import { createStore } from 'vuex'

declare let SessionStorage:any;
const USER = 'USER';
const EXAM = 'EXAM';

const store = createStore({
  state: {
    user: SessionStorage.get(USER) || {},
    exam: SessionStorage.get(EXAM) || {},
  },
  mutations: {
    setUser ( state, user ) {
      state.user = user
      SessionStorage.set(USER, user)
    },
    setExam (state, exam) {
      state.exam = exam
      SessionStorage.set(EXAM, exam)
    }
  },
  actions: {
  },
  modules: {
  }
})
export default store;
