import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

axios.defaults.baseURL = process.env.VUE_APP_SERVER

axios.interceptors.response.use((res:any) => {
    const { data } = res
    if ( data.code === 401 ) {
        ElMessage.error('登录失效，请重新登录！')
        store.commit('setUser', null)
        router.replace({
            name: 'Login'
        })
    } else {
        return res
    }
}, error => {
    return Promise.reject(error)
})

const app = createApp(App);
app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
}).mount('#app')

console.log('环境：', process.env.NODE_ENV)
console.log('服务端：', process.env.VUE_APP_SERVER)
